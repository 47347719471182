var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "addmore",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.addMore.apply(null, arguments)
        },
      },
    },
    [
      _c("p", [
        _c("span", [
          _vm._v("加载更多（"),
          _c("span", { staticClass: "num" }, [
            _vm._v(
              _vm._s(
                _vm.total - _vm.pageNo * _vm.pageSize > 0
                  ? _vm.total - _vm.pageNo * _vm.pageSize
                  : ""
              )
            ),
          ]),
          _vm._v("）"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }