<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-10 09:15:53
 * @LastEditTime: 2021-07-08 16:13:23
 * @LastEditors: Please set LastEditors
 * @Description: 河洛部分使用的加载更多
 * @FilePath: \user\src\views\home\myEn\components\readComm\viewAddMore.vue
-->
<template>
  <div class="addmore" @click.stop="addMore">
    <p>
      <span>加载更多（<span class="num">{{
          total - pageNo * pageSize > 0
            ? total - pageNo * pageSize
            : ""
        }}</span>）</span
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "viewAddMore",
  props: {
    // 总数
    total: {
      type: Number,
      default: 0
    },
    // 页码
    pageNo: {
      type: Number,
      default: 0
    },
    // 数量
    pageSize: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      show: false,
      dom: {}
    };
  },
  mounted() {
  },
  methods: {
    addMore() {
      this.$emit("addMore");
    }
  }
};
</script>

<style lang="scss" scoped>
// 底部的加载更多
.addmore {
  text-align: center;
  border-top: 1px solid #f3f3f3;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 12px;
  color: #91A1B7;
  cursor: pointer;

  p {
    margin: 0 0 10px;
  }
}
</style>
